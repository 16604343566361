<template>
	<LinkWrap
		v-bind="link"
		@mouseenter="preloadVariants"
		ondragstart="return false"
		draggable="false"
	>
		<TnCard
			borderRadius="l"
			class="card"
		>
			<!-- Badge -->
			<template
				v-if="label"
				#badge
			>
				<TnBadge
					:size="isMobile ? '2xs' : 's'"
					compact
					:category="badgeCategory"
					class="badge-container"
				>
					{{ label }}
				</TnBadge>
			</template>
			<!-- Badge -->

			<!-- Image -->
			<div
				class="image-container"
				v-if="loading"
			>
				<TnSkeleton
					height="100%"
					width="100%"
					border-radius="m"
				/>
			</div>
			<div
				v-else
				class="image-container"
				@click="emit('show-modal')"
			>
				<template v-if="slicedProductVariants.length === 0 && image">
					<NuxtImg
						:src="image.md"
						:modifiers="{ trim: 10 }"
						:height="isMobile ? 100 : 200"
						width="auto"
					/>
				</template>
				<template v-else-if="slicedProductVariants.length > 0">
					<NuxtImg
						v-for="variant in slicedProductVariants"
						v-show="variant.id === displayedVariantId"
						:alt="variant.image.alt"
						:src="variant.image.md"
						:loading="variantsPreloaded ? 'eager' : 'lazy'"
						:key="variant.id"
						:modifiers="{ trim: 10 }"
						:height="isMobile ? 100 : 200"
						width="auto"
					/>
				</template>
			</div>
			<!-- Image -->

			<div class="card-content">
				<!-- Heading -->

				<div class="heading">
					<!-- <div class="brand-color"> -->
					<p
						v-if="!loading"
						class="product-brand"
					>
						{{ brand }}
					</p>
					<!-- Color variant -->
					<div class="title-variant-wrapper">
						<ul
							class="variant-selector"
							v-if="!hideVariant && !loading"
						>
							<li
								v-for="variant in slicedProductVariants"
								:key="variant.id"
								@mouseover="changeVariant(variant.id)"
								class="variant"
								:class="{ active: currentVariantId === variant.id }"
								:style="`background-color: ${variant.colorCode || variant.colorcode}`"
								:title="variant.color"
							></li>
							<li
								v-show="moreVariants"
								class="variant more"
							>
								+{{ moreVariants }}
							</li>
						</ul>
					</div>
					<!-- Color variant -->
					<!-- </div> -->
					<TnSkeleton
						height="16px"
						v-if="loading"
						:width="isMobile ? '45%' : '80%'"
						class="product-brand margin-bottom-m"
						border-radius="m"
					/>

					<p
						v-if="!loading"
						class="product-title"
					>
						{{ title }}
					</p>
					<TnSkeleton
						height="20px"
						v-if="loading"
						width="80%"
						class="product-title margin-bottom-xs"
						border-radius="m"
					/>

					<!-- TODO:- Don't show model until discussed with designer -->
					<p
						class="product-model"
						v-if="!loading && isModelNameCategory && false"
					>
						{{ model }}
					</p>
				</div>
				<!-- Heading -->

				<!-- Price -->
				<div class="price-container">
					<div>
						<div
							class="price"
							v-if="loading"
						>
							<div class="tab-price">
								<TnSkeleton
									:height="isMobile ? '21' : '31'"
									width="50%"
									border-radius="m"
									style="margin-bottom: 5px"
								/>
							</div>
							<div style="display: flex; flex-direction: column">
								<TnSkeleton
									v-for="width in ['65%', '35%']"
									:key="width"
									height="12"
									:width
									border-radius="m"
									style="margin-bottom: 5px"
								/>
							</div>
						</div>
						<div
							v-if="!loading"
							class="price"
						>
							<div
								class="tab-price"
								v-if="isOnSale"
							>
								<TnParagraph
									bold
									size="xs"
									responsive
									class="tab-price-prefix"
								>
									{{ currentPricePrefix }}
								</TnParagraph>
								<TnHeading
									bold
									size="m"
									responsive
								>
									{{ currentTab.price }}
								</TnHeading>

								<TnParagraph
									bold
									size="xs"
									responsive
								>
									{{ currentTab.suffix }}
								</TnParagraph>
							</div>
							<div
								class="tab-price"
								v-if="currentTab.price && !isOnSale"
							>
								<TnParagraph
									bold
									size="xs"
									responsive
									class="tab-price-prefix"
								>
									{{ currentPricePrefix }}
								</TnParagraph>
								<TnHeading
									bold
									style="margin-bottom: -5px"
									size="m"
									responsive
								>
									{{ currentTab.price }}
								</TnHeading>

								<TnParagraph
									bold
									size="xs"
									responsive
								>
									{{ currentTab.suffix }}
								</TnParagraph>
							</div>
							<div
								class="tab-price"
								v-show="currentTab.price && isOnSale"
							>
								<TnParagraph
									class="strike-through"
									size="xs"
									responsive
								>
									Før: {{ currentTab.basePrice }} {{ currentTab.suffix }}
								</TnParagraph>
							</div>

							<TnParagraph
								v-if="currentTab.additionalInfo && !hideAdditional && !onlyFullPriceAvailableDisclaimer"
								class="disclaimer-text"
								size="xs"
							>
								{{ currentTab.additionalInfo }}
							</TnParagraph>
						</div>
					</div>
					<TnParagraph
						v-if="onlyFullPriceAvailableDisclaimer && !loading"
						class="full-price-disclaimer-text disclaimer-text"
						size="xs"
					>
						{{ onlyFullPriceAvailableDisclaimer }}
					</TnParagraph>
				</div>
				<!-- Price -->
			</div>

			<!-- Storage availability -->
			<TnSkeleton
				height="20px"
				v-if="loading"
				width="80%"
				border-radius="m"
			/>

			<MainStock
				v-if="!loading"
				:main-stock="mainStock"
				:size="isMobile ? '12px' : '14px'"
				:unVerifiedDeliveryText="unVerifiedDeliveryText"
				:thresholds="thresholds"
				class="stock"
				grid
				:product="selectedProduct"
			/>
			<!-- Storage availability -->
		</TnCard>
	</LinkWrap>
</template>

<script setup lang="ts">
import ResponsiveImageViewModel from "~/helpers/ViewModels/ResponsiveImageViewModel";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel";
import { useStore } from "vuex";
import { formatPrice } from "~/helpers/formatting/price";
import { MonthlyInstallments } from "~/components/ecommerce/types/NumberOfInstallments";
import { ProductCategory } from "~/components/ecommerce/types/ProductCategory";

const {
	badgeCategory,
	isDownPaymentSelected = false,
	unVerifiedDeliveryText,
	product,
	loading = false,
	hideVariant,
	hideAdditional,
	noLink,
} = defineProps<{
	badgeCategory?: string;
	isDownPaymentSelected?: boolean;
	unVerifiedDeliveryText?: string;
	product?: any;
	loading?: boolean;
	hideVariant?: boolean;
	hideAdditional?: boolean;
	noLink?: boolean; // Used when you don't want to use productLink but handle the click event some other way
}>();

const emit = defineEmits(["show-modal"]);

const store = useStore();

type Tab = {
	price?: string;
	basePrice?: string;
	hasPriceRange?: boolean;
	additionalInfo?: string;
	suffix?: string;
};

const currentTab = computed<Tab>(() =>
	isDownPaymentSelected && isEligibleForDownPayment.value ? downPaymentTab.value : fullPriceTab.value,
);
const currentVariantId = ref<string>(product.productId);
const variantsPreloaded = ref<boolean>(false);

onMounted(() => {
	if (slicedProductVariants.value?.length > 0 && !hideVariant) {
		currentVariantId.value = slicedProductVariants.value[0].id;
	}
});

const { isEligibleForDownPayment } = useIsEligibleForDownPayment(product);

const requiresStock = computed<boolean>(() => {
	const categoriesThatRequireStock: ProductCategory[] = store.state.ecommerce.categoriesThatRequireStock;
	return categoriesThatRequireStock.includes(product.category);
});

const onlyFullPriceAvailableDisclaimer = computed<string | false>(() =>
	isDownPaymentSelected && !isEligibleForDownPayment.value ? "Kun fullpris tilgjengelig" : false,
);

const mainStock = computed<string>(() => "Auto");

const isMobile = computed<boolean>(() => {
	const mobileBreakpoint = 768;
	return window?.innerWidth < mobileBreakpoint;
});

const thresholds = computed<any[]>(() => []);

const isModelNameCategory = computed<boolean>(() =>
	[ProductCategory.WATCH, ProductCategory.ACCESSORY].includes(product.category),
);

const title = computed<string>(() => {
	const replaceModel = isModelNameCategory.value && !!model.value;
	const toBeReplaced = replaceModel ? [brand.value, model.value] : [brand.value];
	return product.productName.replace(new RegExp(toBeReplaced.join("|"), "gi"), "").trim();
});

const model = computed<string>(() => product?.model ?? "");

const brand = computed<string>(() => product?.brand ?? "");

const id = computed<string>(() => product.productId);

const label = computed<string | undefined>(() => product?.memberPrice?.campaignData?.badgeText ?? product.productLabel);

const imageHeight = computed<number>(() => {
	if (product.class === "xl") return 359 * 2;
	return 175 * 2;
});

const image = computed<
	| {
			xs: any;
			sm: any;
			md: any;
			xl: any;
			alt: any;
	  }
	| undefined
>(
	() =>
		product.productImage?.image_md?.src && ResponsiveImageViewModel(product.productImage, undefined, imageHeight.value),
);

const variants = computed<any[]>(() => product.variants || []);

const displayedVariantId = computed<string | undefined>(() => {
	if (currentVariantId.value) return currentVariantId.value;
	if (product.variants?.length > 0) {
		if (!product.variants.some((v) => v.id === product.productId)) return product.variants[0].id;
	}
	return undefined;
});

const asSafeString = (str: string): string => {
	if (!str) return "";
	return str
		.toLowerCase()
		.replace(/å/g, "a")
		.replace(/æ/g, "a")
		.replace(/ø/g, "o")
		.replace(/\+/g, "plus")
		.replace(/[^\w\s]/g, "")
		.replace(/\s/g, "-");
};

const link = computed<{ href: "javascript:" } | { to: string }>(() => {
	if (noLink || !product.productLink) return { href: "javascript:" };

	const urlSegments = [
		product.category,
		product.brand || selectedProduct.value.brand,
		selectedProduct.value.title || selectedProduct.value.rawProductName,
		selectedProduct.value.condition || product.condition,
	]
		.map(asSafeString)
		.filter((s) => !!s);

	let url = `/${urlSegments.join("/")}/`;
	if (store.getters["ecommerce/segment"] === "business") url = "/bedrift" + url;

	return { to: url };
});

const gridSortPrices = computed(() => product?.prices?.gridSortPrices);
const fullPrice = computed(() => gridSortPrices.value?.fullPrice?.value);
const basePrice = computed(() => gridSortPrices.value?.basePrice);
const monthlyPrice = computed(() => gridSortPrices.value?.monthly?.value);
const hasFullPricePriceRange = computed(() => gridSortPrices.value?.fullPrice?.hasPriceRange);
const hasMonthlyPricePriceRange = computed(() => gridSortPrices.value?.monthly?.hasPriceRange);

const fullPriceTab = computed<Tab>(() => ({
	price: fullPrice.value ? formatPrice.oneTime(fullPrice.value, { ceil: true }) : undefined,
	basePrice: basePrice.value ? formatPrice.oneTime(basePrice.value, { ceil: true }) : undefined,
	hasPriceRange: hasFullPricePriceRange.value,
}));

const downPaymentTab = computed<Tab>(() => ({
	price: monthlyPrice.value ? formatPrice.oneTime(monthlyPrice.value, { ceil: true }) : undefined,
	basePrice: basePrice.value
		? formatPrice.oneTime(basePrice.value / MonthlyInstallments.MONTHS_36, { ceil: true })
		: undefined,
	hasPriceRange: hasMonthlyPricePriceRange.value,
	additionalInfo: fullPrice.value
		? `Totalt etter 36 md. ${formatPrice.oneTime(fullPrice.value, { ceil: true })}`
		: undefined,
	suffix: " /md.",
}));

const isOnSale = computed<boolean>(() => currentTab.value.price !== currentTab.value.basePrice);

const currentPricePrefix = computed<string>(() =>
	currentTab.value.hasPriceRange ? "Fra: " : isOnSale.value ? "Nå: " : "",
);

const selectedProduct = computed(() => {
	const variant = variants.value.find((variant) => variant.id === displayedVariantId.value);
	return {
		...product,
		productId: displayedVariantId.value || product.productId,
		title: variant?.title,
		brand: variant?.brand,
		condition: variant?.condition,
		inventory: variant ? variant?.inventory : product.inventory,
	};
});

const productVariants = computed<any[]>(() => {
	if (!variants.value || variants.value.length === 0) return [];

	const enrichedVariants = variants.value.map((variant) => ({
		...variant,
		image: variant.image && ResponsiveImageViewModel(variant.image, undefined, imageHeight.value),
		link: variant.link && LinkViewModel(variant.link),
	}));

	const sortedVariants = [
		enrichedVariants.find((v) => v.id === id.value),
		...enrichedVariants.filter((v) => v.id !== id.value),
	]
		.filter((v) => v)
		.sort((a, b) => b.inventory?.[0]?.amount - a.inventory?.[0]?.amount);

	let uniqueVariants = [];
	for (const item of sortedVariants) {
		const isDuplicate = uniqueVariants.find((obj) => obj.colorCode === item.colorCode);
		if (!isDuplicate) {
			uniqueVariants.push(item);
		}
	}

	uniqueVariants = uniqueVariants.sort((a, b) => a?.priority - b?.priority);

	if (requiresStock.value) {
		const sortedUniqueVariantsWithInventory = uniqueVariants
			.filter((v) => v.inventory?.[0]?.amount > 0)
			.sort((a, b) => a.priority - b.priority);
		const sortedUniqueVariantsWithoutInventory = uniqueVariants
			.filter((v) => v.inventory?.[0]?.amount === 0 || !v.inventory)
			.sort((a, b) => a.priority - b.priority);
		uniqueVariants = [...sortedUniqueVariantsWithInventory, ...sortedUniqueVariantsWithoutInventory];
	}

	return uniqueVariants;
});

const slicedProductVariants = computed<any[]>(() => productVariants.value.slice(0, 3));

const moreVariants = computed<number>(() => productVariants.value.length - slicedProductVariants.value.length);

const changeVariant = (variantId: string): void => {
	currentVariantId.value = variantId;
};

const preloadVariants = (): void => {
	variantsPreloaded.value = true;
};
</script>

<style lang="scss" scoped>
.card {
	max-width: 302px;
	height: 530px;
	display: grid !important;
	grid-template-rows: 261px auto 30px;
	flex-direction: column;
	padding: $spacing-3xl $spacing-m $spacing-m $spacing-m;
	box-shadow:
		0 4px 12px 0 rgba(0, 0, 0, 0.04),
		0 2px 8px 0 rgba(0, 0, 0, 0.08),
		0 0 4px -2px rgba(0, 0, 0, 0.12);
	&:hover {
		margin-top: -20px;
		transition: all 0.3s ease;
		box-shadow:
			0 8px 16px 0 rgba(0, 0, 0, 0.04),
			0 2px 8px 0 rgba(0, 0, 0, 0.08),
			0 0 4px -2px rgba(0, 0, 0, 0.12);
	}
	&:not(:hover) {
		transition: all 0.3s ease;
	}

	.badge-container {
		z-index: 1;

		// Text-size from TnBadge not working correctly
		@include breakpoint(mobile) {
			font-size: 12px;
		}
	}

	.image-container {
		width: 100%;
		height: 261px;
		position: relative;
		object-fit: contain;
		display: grid;
		place-items: center;

		img {
			width: 100%;
			height: 200px;
			object-fit: contain;
			transition: all 0.1s ease-in-out;

			@include breakpoint(mobile) {
				width: 100%;
				height: 100px;
			}
		}

		@include breakpoint(mobile) {
			width: 100%;
			height: 100px;
		}
	}

	&-content {
		display: grid;
		grid-template-rows: calc(74px + $spacing-l) auto;

		@include breakpoint(mobile) {
			grid-template-rows: calc(86px + $spacing-s) auto;
		}
	}

	.heading {
		display: grid;
		grid-template-areas:
			"brand color"
			"title title";
		grid-template-rows: 22px 52px auto;
		margin-top: $spacing-l;

		.variant-selector {
			display: flex;
			gap: 2px;
			transform: translate(3px, 2px);

			.variant {
				width: 18px;
				height: 18px;
				display: flex;
				border-radius: 50%;
				border: 2px solid white;
				box-shadow: 0 2px 3px 0 #0003 inset;
				transition: transform 0.3s ease;

				&.active:not(.more) {
					outline: 2px solid $color-cta-active;
				}

				&.more {
					width: auto;
					border-radius: 0;
					box-shadow: none;
					position: relative;
					top: -1px;

					@include font-text-s;
				}

				@include breakpoint(mobile) {
					width: 14px;
					height: 14px;
				}
			}
		}

		.title-variant-wrapper {
			grid-area: color;
			display: flex;
			align-items: center;
			align-self: center;
			justify-content: flex-end;
			height: 20px;

			@include breakpoint(mobile) {
				height: 18px;
			}

			@include breakpoint(mobile) {
				justify-content: flex-start;
			}
		}

		.product {
			&-brand {
				white-space: nowrap;
				overflow: hidden;
				grid-area: brand;
				align-self: center;

				@include font-title-2xs;
			}

			&-title {
				max-height: 52px;
				height: max-content;
				overflow: hidden;
				grid-area: title;

				@include font-title-bold-xs;

				@include breakpoint(mobile) {
					height: 32px;
				}
			}

			&-model {
				white-space: nowrap;
				overflow: hidden;
				grid-area: model;

				@include font-title-2xs;
			}

			@include breakpoint(mobile) {
				&-brand {
					@include font-text-2xs;
				}

				&-title {
					@include font-text-bold-2xs;
				}

				&-model {
					grid-area: color;

					@include font-text-2xs;
				}
			}
		}

		@include breakpoint(mobile) {
			grid-template-rows: 16px 32px auto;
			grid-template-areas:
				"brand brand"
				"title title"
				"color color";
		}

		@include breakpoint(mobile) {
			margin-top: $spacing-m;
		}
	}

	.price {
		.tab-price {
			color: $color-primary-mid;
			display: flex;
			align-items: baseline;

			&-prefix {
				margin-right: $spacing-xs;
			}

			p:not(.strike-through) {
				color: $color-primary-mid;
			}
		}

		.disclaimer-text-extra {
			font-size: 14px !important;
			color: $color-neutrals-600-shade;

			@include breakpoint(mobile) {
				font-size: 12px !important;
			}
		}

		.disclaimer-text {
			font-size: 14px !important;
			color: $color-neutrals-600-shade;

			@include breakpoint(mobile) {
				font-size: 12px !important;
			}
		}

		.strike-through {
			color: $color-neutrals-600-shade;
			text-decoration: line-through;
		}
	}

	.stock {
		font-size: 14px !important;

		@include breakpoint(mobile) {
			font-size: 12px !important;
		}
	}

	@include breakpoint(mobile) {
		grid-template-rows: 100px auto 36px;
	}

	@include breakpoint(mobile) {
		padding: $spacing-2xl $spacing-s $spacing-s $spacing-s;
	}

	@include breakpoint(mobile) {
		height: 340px;

		.title-variant-wrapper {
			display: block;
		}

		.heading {
			.brand-color {
				display: flex;
				margin-bottom: $spacing-xs;
				align-items: center;
			}

			p {
				text-wrap: wrap;
			}
		}

		.price {
			font-weight: bold;
		}
	}
}

.price-container {
	position: relative;

	.full-price-disclaimer-text {
		position: absolute;
		bottom: $spacing-xs;
		margin-left: 6px;
		color: $color-primary-mid !important;
	}
}

.disclaimer-text {
	font-size: 14px !important;
	color: $color-neutrals-600-shade;

	@include breakpoint(mobile) {
		font-size: 12px !important;
	}
}

a {
	width: 280px;

	* {
		max-width: calc(280px - calc($spacing-m * 2));

		@include breakpoint(mobile) {
			max-width: calc(165px - calc($spacing-s * 2));
		}
	}

	@include breakpoint(mobile) {
		width: 165px;
	}
}
</style>
