<template>
	<GridProductsProductCard
		:product="dummy"
		:loading="true"
		v-bind="$attrs"
	></GridProductsProductCard>
</template>

<script>
import { ProductCategory } from "~/components/ecommerce/types/ProductCategory";

export default defineNuxtComponent({
	name: "FakeProduct",

	computed: {
		dummy() {
			return {
				productId: "0",
				productName: "Laster...",
				rawProductName: "Laster...",
				productPrice: [{ text: "Laster...", price: "Laster..." }],
				productDescription: "Vennligst vent",
				productItemGroupId: "0",
				rating: 0,
				brand: "Apple",
				rootCategory: "Nettbutikk Consumer",
				swap: false,
				parentCategory: ProductCategory.ACCESSORY,
				category: ProductCategory.ACCESSORY,
				categorynames: ["Tilbehør", "Ladere"],
			};
		},
	},
});
</script>

<style lang="scss" scoped>
.product {
	display: block;
	padding: 0 $spacing-s;
	animation: fadeIn ease 1.5s;
	margin-top: 10px;

	.product-label {
		height: 12px;

		.news-badge {
			left: 50%;
			transform: translateX(-50%);
			z-index: 1;
		}
	}

	.image-container {
		position: relative;
		padding-top: 90%;
		margin-bottom: $spacing-m;
		border-radius: 15px;
		transition: all 0.1s ease-in-out;

		:deep(.responsive-image) {
			width: auto;
			height: 70%;
			position: absolute;
			margin: auto;
			inset: 0;
			transition: all 0.1s ease-in-out;
		}
	}

	.heading {
		padding-bottom: $spacing-s;
		white-space: normal;
	}

	.info {
		color: $color-neutrals-600-shade;

		@include font-text-m;
	}

	.additional-info {
		color: $color-neutrals-600-shade;

		@include font-text-2xs;
	}

	&:hover {
		.image-container {
			box-shadow:
				0 3px 1px -2px rgb(0 0 0 / 5%),
				0 2px 2px 0 rgb(0 0 0 / 10%),
				0 1px 5px 0 rgb(0 0 0 / 5%);
			transition: all 0.3s ease-in-out;

			:deep(.responsive-image) {
				transform: scale(1.1);
				transition: all 0.3s ease-in-out;
			}
		}
	}
}
</style>
